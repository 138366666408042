import styles from './loading.module.css';

function Loading(props) {
  return (
    <div className={styles.root}>
      <div className={styles.title}>Loading</div>
    </div>
  );
}

export default Loading;
