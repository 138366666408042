import { lazy, Suspense } from 'react';
import { Router } from '@reach/router';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import Loading from './components/loading';
import './App.css';

import NotFound from './routes/404';

const Booking = lazy(() => import('./routes/booking/index'));
const Interpreter = lazy(() => import('./routes/interpreter/index'));
const helmetContext = {};

function App() {
  return (
    <div className="app">
      <HelmetProvider context={helmetContext}>
        <Helmet>
          <title>Signalise</title>
        </Helmet>
        <Suspense fallback={<Loading />}>
          <Router>
            <Booking path="booking/*" />
            <Interpreter path="interpreter/*" />
            <NotFound default />
          </Router>
        </Suspense>
      </HelmetProvider>
    </div>
  );
}

export default App;
