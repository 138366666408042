import styles from './not-found.module.css';
import errorHand from '../images/error-hand.png';

const NotFound = (props) => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <img src={errorHand} width="600" height="890" alt="Hand character looking worried" />
        <p>Sorry. We can't find what you are looking for.</p>
      </div>
    </div>
  );
};
export default NotFound;
